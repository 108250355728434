.mainImg{
    width: 100% !important;
    position: sticky;
    z-index: -1;
  }
.logo{
    display: inline;
}
.logoMobile{
    display: none;
}
.banner-home .carousel-caption{
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    top: 20%;
    left: 30%;
    width: 32.6%;
    animation: slide-in 2000ms;
}

.banner-home .carousel-caption img{
    max-width: 100%;
    width: 100%;
}


.banner-home .carousel-caption h2{
    text-align: right;
    padding: 0px 0px;
    margin: 2px 0px;
}

.banner-home .carousel-caption h2:nth-child(1){
    font-size: 3.98vw;
    font-weight: lighter;
}

.banner-home .carousel-caption h2:nth-child(2){
    font-size: 5vw;
    font-weight: bold;
}

.banner-home .carousel-caption h2:nth-child(3){
    font-size: 2.43vw;
    font-weight: bold;
}

.banner-home .carousel-caption h2:nth-child(4){
    font-size: 1.61vw;
    font-weight: bold;
}

.contact-header{
    margin-top: 20px !important;
    width: 43vh;
    height: 12vh;
    float: right;
}

.btn-contact-header{
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    padding: 15px;
    border-radius: 40px;
    box-shadow: 0px 5px 1px 1px #908b8b;
    margin-bottom: 5%;
}

.btn-contact-header a{
    align-content: center;
    transition: all 0.5s ease;
    text-decoration: none;
    color: #fe5b13;
    font-size: 28px;
    font-weight: bold;
}

.btn-contact-header a:hover{
    transition: all 0.7s ease;
    margin-left: 20px;
    color: #fe5b13;
}
  
@keyframes slide-in {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0%);
    }
  }
  
  @media only screen and (max-width: 800px) {

    .logo{
        display: none;
    }
    .logoMobile{
        display: inline;
    }
    .banner-home .carousel-caption{
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
        top: 10%;
        left: 20%;
        width: 68%;
        animation: slide-in 2000ms;
    }
    
    .banner-home .carousel-caption img{
        max-width: 100%;
        width: 100%;
    }
    
    .banner-home .carousel-caption h2{
        text-align: right;
        padding: 0px 0px;
        margin: 2px 0px;
    }
    
    .banner-home .carousel-caption h2:nth-child(1){
        font-size: 8.3vw;
        font-weight: lighter;
    }
    
    .banner-home .carousel-caption h2:nth-child(2){
        font-size: 10.5vw;
        font-weight: bold;
    }
    
    .banner-home .carousel-caption h2:nth-child(3){
        font-size: 5.1vw;
        font-weight: bold;
    }
    
    .banner-home .carousel-caption h2:nth-child(4){
        font-size: 3.38vw;
        font-weight: bold;
    }
    
    .contact-header{
        margin: 60%  8% auto auto !important;
        width: 110%;   
        height: auto;
    }
    
    .btn-contact-header{
        text-align: center;
        background: #eceded;
        padding: 15px;
        border-radius:100px;
        box-shadow: 0px 1px 10px 0px #908b8b;
    }
    
    .btn-contact-header a{
        transition: all 0.5s ease;
        text-decoration: none;
        color: #fe5b13;
        font-size: 22px;
        font-weight: bold;
        border: #fff;
    }
    
    .btn-contact-header a:hover{
        transition: all 0.7s ease;
        margin-left: 20px;
        color: #fe5b13;
    }
}