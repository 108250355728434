
.wavebot{
    z-index: 11 !important;
    margin-top: -20%;
}
.wavetop{
    z-index: 0 !important;
    position: absolute;
    margin-top: -15%;
}
.mainImgLPLogo{
    width: 15%;
    margin-left: 15%;
}
