footer .footer_coluns {
    background: #3e3e40;
    color: #fff;
    background-repeat: no-repeat;
    font-family: Nunito Sans;
}

.footer_coluns .col-sm-4,
.footer_coluns .col-sm-3 {
    padding: 30px 0px;
}

footer .footer_coluns h2 {
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 30px;
}

footer .footer_coluns a,
footer .footer_coluns p {
    transition: all 0.2s 0s ease-in-out;
    text-decoration: none;
    display: block;
    color: #fff;
    font-size: 17px;
    margin-left: 5px;
    margin-bottom: 10px;
}

.wave_footer {
    width: 100%;
}

.footer_coluns .footer-contact p a {
    margin-left: 0px;
    display: inline-block;
}
.footer-social-midia {
    display: flex;
    justify-content: center;
    flex-flow: row rap;
}
.footer-social-midia a {
    display: inline-block !important;
    margin-top: 20px;
}

.footer-social-midia a svg {
    transition: all 0.2s 0s ease-in-out;
    font-size: 45px;
    margin-left: 15px;
}

.footer_coluns span {
    font-weight: bold;
}

footer .footer_coluns a:hover:not(.footer-social-midia a):not(.footer-contact a) {
    transition: all 0.2s 0s ease-in-out;
    margin-left: 10px;
}

footer .footer_cr div {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

footer .footer_cr div p {
    color: #fe5019;
    font-weight: bold;
    padding: 10px;
    margin-bottom: 0px;
}

.footer_image_top {
    height: 30px;
    padding: 0px !important;
    margin: 0px !important;
    overflow: hidden;
}

.footer_image_top img {
    width: 100%;
    height: 60px;
}

.btn-contact-footer {
    width: 80% !important;
    margin: 0px auto;
    text-align: center;
    padding: 15px;
    background-image:linear-gradient(#fefefe,#eeeeee,#d0d0d0,#767474);
    background-origin: border-box;
    border-radius: 30px;
    border: solid 0.5px transparent;
    box-shadow: 1px 5px 1px #202020 ;
    transition: 0.3s linear
    

}

.btn-contact-footer a {
    transition: all 0.5s ease !important;
    text-decoration: none;
    color: #fe5b13 !important;
    font-size: 22px !important;
    font-weight: bold;
    margin: 0px !important;
}

.btn-contact-footer a:hover {
    transition: all 0.7s ease;
    margin-left: 20px;
    color: #fe5b13;
    
}

.titulo_footer {
    font-size: 35px !important;
    color: #ed4e1c;
    padding: 0px !important;
    margin-bottom: 15px !important;
}

.footer_col_top {
    padding-top: 30px;
    height: 50px;
    align-items: center;
    display: flex;
}

.footer-contact a {
    font-size: 22px !important;
    
}

.col_contact {
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    font-weight: bold;
    margin-top: 20px;
}
.visible_mobile{
    display:none;
}
.footer_imagem_logo{
    margin-bottom: 20px;
}
.footer_imagem_logoDatamais{
    margin-bottom: 20px;
    width:25vw;
}
@media only screen and (max-width: 800px) {
    .col_footer{ 
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .hidden_mobile {
        display: none;
    }
    .visible_mobile{
        display:block;
    }

   .btn-contact-footer{
    width: 80% !important;
    margin-top: 35px !important;
    margin-bottom: 50px !important;
    }
    .footer_coluns .col-sm-4,
    .footer_coluns .col-sm-3 {
        padding: 10px 0px !important;
    }
    .titulo_footer{
        margin-bottom:30px !important;
        font-size: 35px !important;
    }
    .col_contact{
        margin-top:25px !important;
        
    }
    .footer_image_top img{ 
        height: 38px !important;
    }
    .footer-social-midia svg{
        font-size: 40px !important;
    }
    .footer_imagem_logoDatamais{
        margin-bottom: 20px;
        width:50vw;
    }
}
@media only screen and (min-width: 800px) {
    .footer-redes, .footer-contact{
        margin-top:17px !important;
    }

}